import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { currentUserSelector } from '../../../ducks/user.duck';
import signUpIcon from '../../../assets/photos/next-white-arrow.png';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { propTypes } from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';
import { IoIosMenu } from 'react-icons/io';
import css from './TopbarDesktop.module.css';
import { Link, useLocation } from 'react-router-dom/cjs/react-router-dom';

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
  } = props;
  const [mounted, setMounted] = useState(false);
  const location = useLocation();
  const test_data = 1;

  useEffect(() => {
    setMounted(true);
  }, []);
  const { attributes } = currentUser || {};
  const { profile } = attributes || {};
  const { publicData } = profile || {};
  const { userType } = publicData || {};
  const isCreator = userType === 'creator';
  const isBrand = userType === 'brand';
  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const currentUserRole = currentUser?.attributes?.profile?.publicData?.userRoleOption;
  const isInfluencer = currentUserRole === 'influencer';

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: isCreator ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ManageListingsPage">
          {isCreator ? (
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                currentPageClass('ManageListingsPage')
              )}
              name="ManageListingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.yourListingsLink" />
            </NamedLink>
          ) : null}
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;
  const loginMenu = !authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <IoIosMenu className={css.loginMenuIcon} />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="LoginPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('LoginPage'))}
            name="LoginPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.login" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="SignupPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('SignupPage'))}
            name="SignupPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.signup" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            {/* <span className={css.menuItemBorder} /> */}
            {/* <FormattedMessage id="TopbarDesktop.logout" /> */}
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink} id={location.pathname == '/' ? 'landing-signupLink' : ''}>
      <span className={css.signup} id={location.pathname == '/' ? 'landing-signupLink-inner' : ''}>
        Get Started
      </span>
      <span className={css.signupIcon}>
        <svg className={css.signupIconSvg} id={location.pathname == '/' ? 'landing-signupLink-svg' : 'signupLink-svg'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.28 12H17.76" stroke="white" stroke-width="1.92" stroke-linecap="square" stroke-linejoin="round" />
          <path d="M12 5.28003L18.72 12L12 18.72" stroke="white" stroke-width="1.92" stroke-linecap="square" stroke-linejoin="round" />
        </svg>

      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink} id={location.pathname == '/' ? 'landing-loginLink' : ''}>
      <span className={css.login} id={location.pathname == '/' ? 'landing-loginLink-inner' : ''}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  return (
    <nav className={classes}>
      <div className={css.topbarMax} id={location.pathname == '/' ? 'landing' : ''}>
        <LinkedLogo
          className={css.logoLink}
          format="desktop"
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
        />
        <div className={css.midTopContWrap}>
          <Link className={css.topNavLink} id={location.pathname == '/s' ? 'active-topLink' : 'landing-topNavLink'} to="/s">Creators</Link>
          <svg id={location.pathname == '/' ? 'landing-topNavLink-svg' : 'page-topNavLink-svg'} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.545349 4.0352C0.545349 3.24373 0.872015 2.58667 1.52535 2.064C2.17869 1.54133 3.00935 1.28 4.01735 1.28C5.06269 1.28 5.90269 1.54133 6.53735 2.064C7.17201 2.58667 7.48935 3.24373 7.48935 4.0352C7.48935 4.81173 7.17201 5.4688 6.53735 6.0064C5.90269 6.52907 5.06269 6.7904 4.01735 6.7904C3.00935 6.7904 2.17869 6.52907 1.52535 6.0064C0.872015 5.4688 0.545349 4.81173 0.545349 4.0352Z" fill="white" />
          </svg>

          <Link className={css.topNavLink} id={location.pathname == '/p/pricing' ? 'active-topLink' : 'landing-topNavLink'} to="/p/pricing">Pricing</Link>
          <svg id={location.pathname == '/' ? 'landing-topNavLink-svg' : 'page-topNavLink-svg'} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.545349 4.0352C0.545349 3.24373 0.872015 2.58667 1.52535 2.064C2.17869 1.54133 3.00935 1.28 4.01735 1.28C5.06269 1.28 5.90269 1.54133 6.53735 2.064C7.17201 2.58667 7.48935 3.24373 7.48935 4.0352C7.48935 4.81173 7.17201 5.4688 6.53735 6.0064C5.90269 6.52907 5.06269 6.7904 4.01735 6.7904C3.00935 6.7904 2.17869 6.52907 1.52535 6.0064C0.872015 5.4688 0.545349 4.81173 0.545349 4.0352Z" fill="white" />
          </svg>

          <Link className={css.topNavLink} id={location.pathname == '/p/resources' ? 'active-topLink' : 'landing-topNavLink'} to="/p/resources">Resources</Link>
          <svg id={location.pathname == '/' ? 'landing-topNavLink-svg' : 'page-topNavLink-svg'} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.545349 4.0352C0.545349 3.24373 0.872015 2.58667 1.52535 2.064C2.17869 1.54133 3.00935 1.28 4.01735 1.28C5.06269 1.28 5.90269 1.54133 6.53735 2.064C7.17201 2.58667 7.48935 3.24373 7.48935 4.0352C7.48935 4.81173 7.17201 5.4688 6.53735 6.0064C5.90269 6.52907 5.06269 6.7904 4.01735 6.7904C3.00935 6.7904 2.17869 6.52907 1.52535 6.0064C0.872015 5.4688 0.545349 4.81173 0.545349 4.0352Z" fill="white" />
          </svg>
          <Link className={css.topNavLink} id={location.pathname == '/p/rewards' ? 'active-topLink' : 'landing-topNavLink'} to="/p/rewards">Creator Program</Link>
          <svg id={location.pathname == '/' ? 'landing-topNavLink-svg' : 'page-topNavLink-svg'} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.545349 4.0352C0.545349 3.24373 0.872015 2.58667 1.52535 2.064C2.17869 1.54133 3.00935 1.28 4.01735 1.28C5.06269 1.28 5.90269 1.54133 6.53735 2.064C7.17201 2.58667 7.48935 3.24373 7.48935 4.0352C7.48935 4.81173 7.17201 5.4688 6.53735 6.0064C5.90269 6.52907 5.06269 6.7904 4.01735 6.7904C3.00935 6.7904 2.17869 6.52907 1.52535 6.0064C0.872015 5.4688 0.545349 4.81173 0.545349 4.0352Z" fill="white" />
          </svg>
          <Link className={css.topNavLink} id={location.pathname == '/p/about-us-page' ? 'active-topLink' : 'landing-topNavLink'} to="/p/about-us-page">About Us</Link>
          
        </div>
        {/* {search} */}
        <div className={css.right}>
          {isCreator ? (
            <NamedLink className={classNames(css.createListingLink)} name="NewListingPage">
              <span className={css.createListing}>
                <FormattedMessage id="TopbarDesktop.createListing" />
              </span>
            </NamedLink>
          ) : null}
          {inboxLink}
          {/* {loginMenu} */}
          {profileMenu}
          {loginLink}
          {signupLink}
        </div>
      </div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
