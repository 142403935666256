import React, { useEffect, useState } from "react";
import css from './SectionBrandLoveMob.module.css';
import brandLove1 from '../../../assets/photos/brand-love1.png';
import brandLove2 from '../../../assets/photos/brand-love2.png';
import brandLove3 from '../../../assets/photos/brand-love3.png';
import brandLove4 from '../../../assets/photos/brand-love4.png';
import { Carousel } from 'react-responsive-carousel';
import leftArrow from '../../../assets/photos/next-arrow.png';
import rightArrow from '../../../assets/photos/prev-arrow.png';

const SectionBrandLoveMob = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    
    const handleChange = index => {
        setCurrentIndex(index);
    };
    const text = [
        {
            heading: 'Sarah L.',
            label: 'Marketing Director at BrightWave',
            image: brandLove1,
            text: (
                <p className={css.text}>
                    “ukreate has revolutionized the way we collaborate with creators. The platform's intuitive interface and vast network have significantly boosted our campaign results.”
                </p>
            ),
        },
        {
            heading: 'Michael R.',
            label: 'Brand Manager at EcoTech Innovations',
            image: brandLove2,
            text: (
                <p className={css.text}>
                    “Finding the right creators used to be a challenge. With Ukreate, we've connected with influencers who truly understand our brand values, leading to more authentic and engaging content.”
                </p>
            ),
        },
        {
            heading: 'Lisa M.',
            label: 'Chief Marketing Officer at Zenith Fitness',
            image: brandLove3,
            text: (
                <p className={css.text}>
                    “ukreate has been a game-changer for our marketing efforts. We connected with creators who not only understand our brand but also share our passion for wellness and fitness. The content they've produced has significantly increased our engagement rates. The platform's intuitive interface and dedicated support make collaboration effortless. Ukreate is now an integral part of our marketing strategy.”
                </p>
            ),
        },
        {
            heading: 'Michael R.',
            label: 'Brand Manager at EcoTech Innovations',
            image: brandLove4,
            text: (
                <p className={css.text}>
                    “ukreate simplifies the process of finding and collaborating with talented creators. The platform's search tools and personalized recommendations have helped us discover the perfect partners for our campaigns. The results speak for themselves—higher engagement, better ROI, and a seamless experience from start to finish. Highly recommend!”
                </p>
            ),
        },
    ];
    const renderArrowPrev = (onClickHandler, hasPrev, label) => (
            <button
                type="button"
                onClick={onClickHandler}
                className={css.leftArrow}
                aria-label={label}
                disabled={!hasPrev}
            >
                <img src={leftArrow} alt="Previous" className={css.arrow} />
            </button>
    );

    const renderArrowNext = (onClickHandler, hasNext, label) => (
        <button
            type="button"
            onClick={onClickHandler}
            className={css.rightArrow}
            aria-label={label}
            disabled={!hasNext}
        >
            <img src={rightArrow} alt="Next" className={css.arrow} />
        </button>
    );

    return (
        <div className={css.brandLoveWrap}>
            <div className={css.container}>
                <div className={css.brandLoveContainer}>
                    <div className={css.brandLoveTopCont}>
                        <h2 className={css.brandLoveHeading}>Brands Love Us</h2>
                    </div>
                    <Carousel
                        autoPlay={true}
                        showStatus={false}
                        showThumbs={false}
                        showArrows={true}
                        showIndicators={true}
                        infiniteLoop={true}
                        swipeable={true}
                        transitionTime={500}
                        interval={5000}
                        onChange={handleChange}
                        renderArrowPrev={renderArrowPrev}
                        renderArrowNext={renderArrowNext}
                        // centerMode={true}
                        // centerSlidePercentage={100}
                        className={css.finalSlider}
                    >
                        {text.map((item, index) => (
                            <div className={css.brandLoveSlider} key={index}>
                                <div className={css.brandSliderCont}>
                                    {item.text}
                                    <div className={css.brandAuthWrap}>
                                        <div className={css.brandSlideimg}>
                                            <img src={item.image} alt="brand-slide-img" />
                                        </div>
                                        <div className={css.brandSlideContWrap}>
                                            <h3 className={css.brandSlideHead}>{item.heading}</h3>
                                            <p className={css.brandSlideLabel}>{item.label}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                </div>
            </div>
        </div>
    )
}

export default SectionBrandLoveMob;
